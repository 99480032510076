import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { rgba } from 'polished';

import Caret from './Caret';

const Pagination = ({ limit, skip, numPages, currentPage }) => {
  const slug = 'stories';
  const prev = currentPage > 1 ? currentPage - 1 : false;
  const next = currentPage === numPages ? false : currentPage + 1;

  const twoBack = currentPage > 2 ? currentPage - 2 : false;
  const twoAhead = (numPages - currentPage) > 1 ? currentPage + 2 : false;
  const threeBack = currentPage > 3 ? currentPage - 3 : false;
  const threeAhead = (numPages - currentPage) > 2 ? currentPage + 3 : false;

  return (
    <StyledPagination role="navigation" aria-label="Pagination Navigation">
      {prev && (
        <Link
          className="page-number prev"
          to={`/${slug}/${prev === 1 ? '' : prev}`}
          aria-label="Go to previous page"
        >
          <Caret rotate="180deg" />
        </Link>
      )}
      {threeBack && (
        <Link
          className="page-number"
          to={`/${slug}/${threeBack === 1 ? '' : threeBack}`}
          aria-label={`Go to page ${threeBack}`}
        >
          {threeBack}
        </Link>
      )}
      {twoBack && (
        <Link className="page-number" to={`/${slug}/${twoBack === 1 ? '' : twoBack}`} aria-label={`Go to page ${twoBack}`}>{twoBack}</Link>
      )}
      {prev && (
        <Link className="page-number" to={`/${slug}/${prev === 1 ? '' : prev}`} aria-label={`Go to page ${prev}`}>{prev}</Link>
      )}
      {numPages && (
        <span className="page-number current">{currentPage}</span>
      )}
      {next && (
        <Link className="page-number" to={`/${slug}/${next === 1 ? '' : next}`} aria-label={`Go to page ${next}`}>{next}</Link>
      )}
      {twoAhead && (
        <Link className="page-number" to={`/${slug}/${twoAhead === 1 ? '' : twoAhead}`} aria-label={`Go to page ${twoAhead}`}>{twoAhead}</Link>
      )}
      {threeAhead && (
        <Link className="page-number" to={`/${slug}/${threeAhead === 1 ? '' : threeAhead}`} aria-label={`Go to page ${threeAhead}`}>{threeAhead}</Link>
      )}
      {next && (
        <Link className="page-number next" to={`/${slug}/${next === 1 ? '' : next}`} aria-label="Go to next page">
          <Caret />
        </Link>
      )}
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  text-align: center;
  margin: 5rem auto;
  background: ${({ theme }) => theme.green};
  display: inline-block;
  padding: .5rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .page-number {
    color: white;
    margin: 0 .5rem;
    font-size: 1.8rem;
    text-decoration: none;
    &.current {
      color: ${rgba('white', .7)};
    }
  }
`;

export default Pagination;