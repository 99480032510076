import React from 'react';
import styled from 'styled-components';

import PageSidebar from './PageSidebar';
import StoriesList from './StoriesList';
import Pagination from './Pagination';
import Content from './Content';

import useSiteContext from './SiteContext';
import { media } from './theme';


const StoriesArchive = ({ className, stories, pageContext, title, mainImage, _rawContent, seoSettings }) => {
  const { numPages } = pageContext;
  const { viewport, mobile } = useSiteContext();
  return (
    <PageWrapper className="story-archive-wrapper" viewHeight={viewport.height}>
      <PageSidebar title="Stories" mainImage={mainImage} />
      <div className="page-content">
        {!mobile && (
          <h1 className="page-title">{title}</h1>
        )}
        {_rawContent && (
          <Content main>
            {_rawContent}
          </Content>
        )}
        <StoriesList stories={stories} />
        {numPages > 1 && (
          <Pagination {...pageContext} />
        )}
      </div>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  .page-title {
    color: ${({ theme }) => theme.purple};
    margin-bottom: 5rem;
  }
  ${media.break`
    display: flex;
    flex-flow: row wrap;
  `}

  .page-content {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;
    ${media.break`
      flex-grow: 1;
      order: 1;
      flex: 0 1 800px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 4rem;
      padding-left: 3rem;
      padding-right: 3rem;
      overflow: scroll;
      height: ${({ theme }) => `calc(100vh - ${theme.headerHeight.desktop}px)`};
      height: ${({ viewHeight, theme }) => viewHeight - theme.headerHeight.desktop}px;
      font-size: 2rem;
    `}
  }
`;

export default StoriesArchive;