import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Wrapper from '../components/Wrapper';
import StoriesArchive from '../components/StoriesArchive';

const storiesArchive = ({ data, pageContext, location }) => {
  const { sanityStoriesPage, allSanityStory } = data;
  return (
    <Wrapper seo={{seoSettings: sanityStoriesPage.seoSettings, title: sanityStoriesPage.title }}  location={location}>
      <Helmet>
        <link rel="canonical" href={`${location.origin}/${location.pathname.split('/')[1]}`} />
      </Helmet>
      <StoriesArchive stories={allSanityStory.edges} pageContext={pageContext} {...sanityStoriesPage} className="stories-archive" />
    </Wrapper>
  );
};

export const StoriesQuery = graphql`
  query storiesQuery($skip: Int!, $limit: Int!) {
    allSanityStory(limit: $limit, skip: $skip, sort: {fields: publishedAt, order: DESC}) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM D, YYYY")
          mainImage {
            alt
            asset {
              fixed(height: 100, width: 100) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
    sanityStoriesPage(_id: {eq: "storiesPage"}) {
      title
      seoSettings {
        metaDescription
        titleTag
      }
      mainImage {
        alt
        asset {
          fluid(maxWidth: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawContent
    }
  }
`;



export default storiesArchive;